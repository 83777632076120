
import {Component, Vue} from "vue-property-decorator";
import {VekaletEntity} from "@/entity/VekaletEntity";
import VekaletList from "@/components/lists/VekaletList.vue";

@Component({
  components: {VekaletList}
})
export default class VekaletListeView extends Vue {

}
